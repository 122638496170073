<template>
  <v-app class="transparent">
    <v-container
      id="regular-tables"
      fluid
      tag="section"
    >
      <base-v-component
        heading="Prescription List"
      />

      <v-card
        icon="mdi-domain"
        title="Prescription List"
        class="px-5 py-3"
        :loading="loading"
      >
        <v-simple-table>
          <filter-list
            :filters="filter"
            :on-change="fetchList"
          />
          <tbody>
            <tr
              v-for="prescription in prescriptions"
              :key="prescription.id"
            >
              <td>{{ getSafe(() => prescription.id) }}</td>
              <td>{{ getSafe(() => prescription.transferred_at) }}</td>
              <td>{{ getSafe(() => prescription.phr.name) }}</td>
              <td>{{ getSafe(() => prescription.tw_user.policy_number) }}</td>
              <td>{{ getSafe(() => prescription.phr.phone) }}</td>
              <td>{{ getSafe(() => prescription.tw_user.phone) }}</td>
              <td>{{ getSafe(() => prescription.phr.national_no) }}</td>
              <td>{{ getSafe(() => prescription.phr.member.city.name) }}</td>
              <td
                v-if="canModify"
                class="text-center"
              >
                <div class="d-inline-flex">
                  <v-btn
                    x-small
                    fab
                    color="green"
                    class="m-2 ma-2"
                    :to="{ name: 'prescriptions.update', params: {id:prescription.id}}"
                  >
                    <v-icon>mdi-square-edit-outline</v-icon>
                  </v-btn>

                  <v-btn
                    x-small
                    fab
                    color="green"
                    class="mr-2 ma-2"
                    :to="{ name: 'prescriptions.show', params: {id:prescription.id}}"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
      <div class="text-center">
        <v-pagination
          v-if="pagination.total > 10"
          v-model="query.page"
          :length="pagination.length"
          :total-visible="7"
          @input="fetchList"
          @next="fetchList"
          @previous="fetchList"
        />
      </div>
    </v-container>
  </v-app>
</template>

<script>
import FilterList from "../../components/base/FilterList";

export default {
  name: "BranchesList",
  components: { FilterList },
  data () {
    return {
      prescriptions: [],
      pagination: {
        page: 1,
        length: 0,
        total: 0
      },
      query: {
        page: 1
      },
      filter: [
        {
          name: "page",
          value: 1,
          type: "pagination"
        },
        {
          name: "id",
          value: "",
          type: "text_field"
        },
        {
          name: "transferred_at",
          value: "",
          type: "text_field"
        },
        {
          name: "phr_name",
          value: "",
          type: "text_field"
        },
        {
          name: "policy_number",
          value: "",
          type: "text_field"
        },
        {
          name: "phr_phone",
          value: "",
          type: "text_field"
        },
        {
          name: "tw_user_phone",
          value: "",
          type: "text_field"
        },
        {
          name: "national_id",
          value: "",
          type: "text_field"
        },
        {
          name: "city",
          value: "",
          type: "text_field"
        },
        {
          name: "options",
          value: "",
          type: "options"
        },
      ],
      loading: false,
    }
  },
  computed: {
    canModify () {
      return this.$auth.isAdmin() || this.$auth.isSuperPharmacist() || this.$auth.isPharmacist();
    }
  },
  watch: {
    $route () {
      this.getUrlParams();
      this.fetchList();
    }
  },
  created () {
    this.getUrlParams();
    this.fetchList();
  },
  methods: {
    async fetchList () {
      try {
        this.loading = true

        const { data: { data, last_page, total } } = await this.$authApi.get(`/prescriptions`, {
          params: this.query
        })

        this.prescriptions = data;
        this.pagination.length = parseInt(last_page);
        this.pagination.total = parseInt(total);
      } catch (e) {

      } finally {
        this.loading = false
      }
    },
    getUrlParams () {
      if (!Object.keys(this.$route.query).length) {
        this.query = {
          page: 1
        };
        this.$root.$emit('refreshParams', this.filter)
      } else {
        this.query = this.$route.query
        if ('page' in this.query && this.query.page) {
          this.query.page = parseInt(this.query.page);
        }

        this.filter.forEach(param => {
          param.value = this.query[param.name];
        });
      }
    },
    getSafe (fn) {
      try {
        return fn();
      } catch (e) {
      }
    }
  }
}
</script>
