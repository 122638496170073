<template>
  <v-container fluid>
    <v-card
      icon="mdi-domain"
      title="Prescription List"
      class="px-5 py-3"
      :loading="loading"
    >
      <v-card-title>
        {{ $t('prescription') }}
      </v-card-title>

      <v-card-text>
        <v-simple-table>
          <tbody>
            <tr
              v-for="(item, i) in items"
              :key="i"
            >
              <td>{{ item.name }}</td>
              <td>
                <v-chip
                  class="ma-2 title"
                  color="default"
                >
                  {{ item.value }}
                </v-chip>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
  export default {
    name: 'Show',
    data () {
      return {
        items: [],
        loading: false
      }
    },
    async mounted () {
      try {
        this.loading = true
        const { data: prescription } = await this.$authApi.get(`/prescriptions/${this.$route.params.id}`)

        this.items = [
          { name: this.$t('request_date'), value: prescription?.transferred_at ?? this.$t('not_set') },
          { name: this.$t('phr_name'), value: prescription?.phr?.name ?? this.$t('not_set') },
          { name: this.$t('phr_phone'), value: prescription?.phr?.phone ?? this.$t('not_set') },
          { name: this.$t('tw_user_phone'), value: prescription.tw_user.phone ?? this.$t('not_set') },
          { name: this.$t('national_id'), value: prescription.phr?.national_no ?? this.$t('not_set') },
          { name: this.$t('city'), value: prescription.phr?.member?.city?.name ?? this.$t('not_set') },
          { name: this.$t('policy_number'), value: prescription?.tw_user?.policy_number ?? this.$t('not_set') },
          { name: this.$t('delivery_status'), value: prescription?.delivery_status?.name ?? this.$t('not_set') },
          { name: this.$t('rejection_reason'), value: prescription?.reason?.reason_text ?? this.$t('not_set') },
          { name: this.$t('delivery_date'), value: prescription?.delivery_date ?? this.$t('not_set') },
          { name: this.$t('approval_number'), value: prescription?.approval_number ?? this.$t('not_set') },
          { name: this.$t('partner_notes'), value: prescription?.partner_note ?? this.$t('not_set') },
        ];
      } catch (e) {
        if (e?.response?.status === 404) {
          await this.$router.push({ name: 'prescriptions.list' })
        }
      } finally {
        this.loading = false
      }
    },
  }
</script>
<style scoped>

</style>
